<template>
  <v-card>
    <v-card-title> Create Canbus Values Config </v-card-title>
    <v-card-text>
      <v-btn
        small
        color="primary"
        @click="loadFromJson">
        LOAD FROM JSON
        <v-icon
          right
          dark
          small>
          mdi-cloud-upload
        </v-icon>
      </v-btn>
      <v-dialog
        v-model="dialogLoadFromJson"
        persistent>
        <load-canbus-values-config-from-json
          @cancel="
            json = ''
            dialogLoadFromJson = false
          "
          @update="update"
          :jsonvalue="json" />
      </v-dialog>
    </v-card-text>

    <v-card-text>
      <v-select
        :items="adminGroups"
        label="Admin Group"
        item-text="name"
        item-value="id"
        prepend-icon="mdi-account-group"
        v-model="selectAdminGroup"></v-select
    ></v-card-text>
    <v-card-text>
      <v-text-field
        v-model="config.configuration_name"
        label="Name"
        prepend-icon="mdi-comment-edit-outline"></v-text-field>
    </v-card-text>
    <v-container>
      <div class="text-center">
        <v-btn
          small
          color="secondary"
          @click="openDialogAddData"
          >ADD DATA</v-btn
        >
      </div>
      <v-card outlined>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="config.datas"
          dense
          disable-pagination
          :expanded.sync="expanded"
          show-expand
          item-key="id">
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length">
              <v-container fluid>
                <v-row no-gutters>
                  <v-col cols="12">
                    <a-counter-canbus-values-configs :config="item" />
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              color="error"
              small
              @click="removeData(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="cancel"
        color="error"
        >CANCEL</v-btn
      >
      <v-btn
        @click="upload"
        color="success"
        >OK</v-btn
      >
    </v-card-actions>
    <v-dialog
      v-model="dialogAddData"
      persistent>
      <addDataCanbusValuesConfig
        @addData="addData"
        @cancel="dialogAddData = false" />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import addDataCanbusValuesConfig from './AddDataCanbusValuesConfig.vue'
import aCounterCanbusValuesConfigs from '../counters/aCounterCanbusValuesConfigs.vue'

import LoadCanbusValuesConfigFromJson from './LoadCanbusValuesConfigFromJson.vue'

export default {
  data: () => ({
    expanded: [],

    headers: [
      { text: 'Data', value: 'nameTable', align: 'start' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'end',
      },
      { text: '', value: 'data-table-expand' },
    ],

    selectAdminGroup: '',
    config: {
      configuration_name: '',
      datas: [],
    },
    dialogAddData: false,
    dialogLoadFromJson: false,
    json: '',
  }),
  components: {
    addDataCanbusValuesConfig,
    aCounterCanbusValuesConfigs,
    LoadCanbusValuesConfigFromJson,
  },
  computed: {
    ...mapGetters(['adminGroups']),
  },
  methods: {
    ...mapActions(['createCanbusValuesConfig']),
    openDialogAddData() {
      this.dialogAddData = true
    },
    addData(val) {
      const locale = moment.locale()
      let name = val.name.find((tps) => tps.languages.includes(locale))
      if (name === undefined) {
        name = val.name.find((tps) => tps.languages.includes('default'))
      }

      if (name === undefined) {
        ;[name] = val.name
      }

      if (name === undefined) {
        name = {
          text: 'No name',
        }
      }
      const tps = {
        nameTable: name.text,
        id: uuidv4(),
        ...val,
      }
      this.config.datas.push(tps)
    },
    removeData(item) {
      const indexToDelete = this.config.datas.indexOf(item)
      this.config.datas.splice(indexToDelete, 1)
    },
    reset() {
      this.config.configuration_name = ''
      this.config.datas = []
    },
    cancel() {
      this.reset()
      this.$emit('close')
    },
    async upload() {
      const tps = {
        adminGroup: this.selectAdminGroup,
        configuration_name: this.config.configuration_name,
        datas: this.config.datas.map((elem) => ({
          name: elem.name,
          unit: elem.unit,
          access_levels: elem.access_levels,
          can_read: elem.can_read
            ? {
                can_id: elem.can_read.can_id,
                extended_id: elem.can_read.extended_id,
                id_mask: elem.can_read.id_mask,
                start_bit: elem.can_read.start_bit
                  ? parseInt(elem.can_read.start_bit, 10)
                  : elem.can_read.start_bit,
                len: elem.can_read.len ? parseInt(elem.can_read.len, 10) : elem.can_read.len,
                byte_order: elem.can_read.byte_order,
                offset: elem.can_read.offset
                  ? parseFloat(elem.can_read.offset, 10)
                  : elem.can_read.offset,
                factor: elem.can_read.factor
                  ? parseFloat(elem.can_read.factor, 10)
                  : elem.can_read.factor,
                min_value: elem.can_read.min_value
                  ? parseFloat(elem.can_read.min_value, 10)
                  : elem.can_read.min_value,
                max_value: elem.can_read.max_value
                  ? parseFloat(elem.can_read.max_value, 10)
                  : elem.can_read.max_value,
                can_mask: elem.can_read.can_mask
                  ? elem.can_read.can_mask.map((e) => parseInt(e))
                  : elem.can_read.can_mask,
                can_data: elem.can_read.can_data
                  ? elem.can_read.can_data.map((e) => parseInt(e))
                  : elem.can_read.can_data,
                type: elem.can_read.type,
              }
            : {},
          can_write: elem.can_write
            ? {
                id: elem.can_write.id,
                extended: elem.can_write.extended,
                start_bit: elem.can_write.start_bit
                  ? parseInt(elem.can_write.start_bit, 10)
                  : elem.can_write.start_bit,
                len: elem.can_write.len ? parseInt(elem.can_write.len, 10) : elem.can_write.len,
                byte_order: elem.can_write.byte_order,
                offset: elem.can_write.offset
                  ? parseFloat(elem.can_write.offset, 10)
                  : elem.can_write.offset,
                factor: elem.can_write.factor
                  ? parseFloat(elem.can_write.factor, 10)
                  : elem.can_write.factor,
                min_value: elem.can_write.min_value
                  ? parseFloat(elem.can_write.min_value, 10)
                  : elem.can_write.min_value,
                max_value: elem.can_write.max_value
                  ? parseFloat(elem.can_write.max_value, 10)
                  : elem.can_write.max_value,
                type: elem.can_write.type,
                default_frame: elem.can_write.default_frame,
              }
            : {},
          can_request: elem.can_request
            ? {
                id: elem.can_request.id,
                extended: elem.can_request.extended,
                start_bit: elem.can_request.start_bit
                  ? parseInt(elem.can_request.start_bit, 10)
                  : elem.can_request.start_bit,
                len: elem.can_request.len
                  ? parseInt(elem.can_request.len, 10)
                  : elem.can_request.len,
                byte_order: elem.can_request.byte_order,
                offset: elem.can_request.offset
                  ? parseFloat(elem.can_request.offset, 10)
                  : elem.can_request.offset,
                factor: elem.can_request.factor
                  ? parseFloat(elem.can_request.factor, 10)
                  : elem.can_request.factor,
                min_value: elem.can_request.min_value
                  ? parseFloat(elem.can_request.min_value, 10)
                  : elem.can_request.min_value,
                max_value: elem.can_request.max_value
                  ? parseFloat(elem.can_request.max_value, 10)
                  : elem.can_request.max_value,
                type: elem.can_request.type,
                default_frame: elem.can_request.default_frame,
              }
            : {},
        })),
      }
      try {
        const respTps = await this.createCanbusValuesConfig(tps)
        this.$emit('addCanbusValuesConfig', respTps)
        this.cancel()
      } catch (error) {
        console.log(error)
      }
    },
    update(val) {
      this.config.configuration_name = val.configuration_name
      this.config.datas = []
      val.datas.forEach((elem) => this.addData(elem))
    },
    loadFromJson() {
      this.dialogLoadFromJson = true
      const tps = {
        configuration_name: this.config.configuration_name,
        datas: this.config.datas.map((elem) => ({
          name: elem.name,
          unit: elem.unit,
          access_levels: elem.access_levels,
          can_read: elem.can_read,
          can_write: elem.can_write,
          can_request: elem.can_request,
        })),
      }
      this.json = String(JSON.stringify(tps, null, 4))
    },
  },
}
</script>

<style>
.dataList {
  background-color: grey;
}
</style>
